import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';

import { getLoading } from '../selectors/app';
import { getSignedIn } from '../selectors/auth';
import { Page, PageItem } from '../types/routing';
import Layout from './layout';
import Loader from './loader';

export const PAGES_IDS_WITHOUT_LAYOUT: Page[] = [Page.Login];
const DEFAULT_PAGE_ID = Page.RoutesSummary;

export const PAGES_WITHOUT_LAYOUT: PageItem[] = [
  {
    component: lazy(() => import('../pages/login')),
    exact: true,
    id: Page.Login,
    path: `/${Page.Login}`,
  },
];

export const PAGES: PageItem[] = [
  {
    component: lazy(() => import('../pages/routes-summary')),
    exact: true,
    id: Page.RoutesSummary,
    path: `/${Page.RoutesSummary}`,
  },
  {
    component: lazy(() => import('../pages/route-tracking')),
    exact: true,
    id: Page.RouteTracking,
    path: `/${Page.RouteTracking}`,
  },
  {
    component: lazy(() => import('../pages/routes-history')),
    exact: true,
    id: Page.RoutesHistory,
    path: `/${Page.RoutesHistory}`,
  },
  {
    component: lazy(() => import('../pages/account-information')),
    exact: true,
    id: Page.AccountInformation,
    path: `/${Page.AccountInformation}`,
  },
];

interface PagesProps {
  location: any;
}

const Pages: React.FC<PagesProps> = ({ location }) => {
  const isAuthenticated = useSelector(getSignedIn);
  const isLoading = useSelector(getLoading);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated === false && isLoading === false) {
      history.push(`/${Page.Login}`);
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (
      (location.pathname === '/' || location.pathname === `/${Page.Login}`) &&
      isAuthenticated === true &&
      isLoading === false
    ) {
      history.push(`/${DEFAULT_PAGE_ID}`);
    }
  }, [location, isAuthenticated, isLoading]);

  return isLoading ? (
    <Loader />
  ) : (
    <Switch>
      {PAGES_IDS_WITHOUT_LAYOUT.includes(
        location.pathname.split('/')[1],
      ) ? null : (
        <Layout location={location}>
          <Suspense fallback={<Loader />}>
            {PAGES.map((route: PageItem) => (
              <Route
                key={route.id}
                exact={route.exact}
                path={route.path}
                render={props => (
                  <route.component {...props} routes={route?.children} />
                )}
              />
            ))}
          </Suspense>
        </Layout>
      )}

      <Suspense fallback={<Loader />}>
        {PAGES_WITHOUT_LAYOUT.map((route: PageItem) => (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            render={props => (
              <route.component {...props} routes={route?.children} />
            )}
          />
        ))}
      </Suspense>
    </Switch>
  );
};

export default withRouter(Pages);
