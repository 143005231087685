import { UsersEntity } from '@9e15/com-uniheap-firestore-schema';
import { isNil, lensProp, view } from 'ramda';
import {
  all,
  call,
  cancel,
  put,
  select,
  takeLeading,
} from 'redux-saga/effects';

import {
  initializeLastOrganizationId,
  updateSnapshot,
} from '../actions/organizations';
import { firestore } from '../fragments/firebase-firestore';
import { getAccountSnapshot } from '../selectors/account';
import { getUid } from '../selectors/auth';
import { getOrganizationId } from '../selectors/organizations';
import { getAdministrator } from '../selectors/permissions';
import { collectionSnapshotWorker } from './utils/firestore';
import { takeSelection } from './utils/state';
import { parametrizedWorker } from './utils/workers';

const database = firestore();

const getOrganizationsReference = (uid: string, administrator?: boolean) =>
  administrator === true
    ? database.collection('organizations')
    : database.collection(`users/${uid}/organizations`);

export const snapshotSaga = function*() {
  yield parametrizedWorker([getAdministrator, getUid], function*([
    administrator,
    uid,
  ]) {
    if (!isNil(uid)) {
      yield call(
        collectionSnapshotWorker,
        getOrganizationsReference(uid, administrator),
        updateSnapshot,
      );
    }
  });
};

export const syncLastOrganizationIdWorker = (uid: string) =>
  function*() {
    const value = yield select(getOrganizationId);
    const currentValue = view(
      lensProp('lastOrganizationId'),
      yield select(getAccountSnapshot),
    );

    if (value === currentValue) {
      return;
    }

    const reference = database.collection('users').doc(uid);

    try {
      yield call(
        [reference, 'set'],
        { lastOrganizationId: value },
        { merge: true },
      );
    } catch (error) {
      console.error(error);
    }
  };

export const syncLastOrganizationIdSaga = function*() {
  let uid: string | undefined = yield select(getUid);

  while (true) {
    const [task] =
      uid === undefined
        ? []
        : yield all([
            takeLeading(
              ['@@organizations/SYNC_LAST_ORGANIZATION_ID'],
              syncLastOrganizationIdWorker(uid),
            ),
          ]);

    uid = yield takeSelection(getUid);

    if (!isNil(task)) {
      yield cancel(task);
    }
  }
};

export const initializeLastOrganizationIdSaga = function*() {
  let userEntity: UsersEntity | null | undefined = yield select(
    getAccountSnapshot,
  );

  while (true) {
    if (userEntity !== undefined) {
      const lastOrganizationId: string | undefined = isNil(userEntity)
        ? undefined
        : view(lensProp('lastOrganizationId'), userEntity);

      yield put(initializeLastOrganizationId(lastOrganizationId));

      return;
    }

    userEntity = yield takeSelection(getAccountSnapshot);
  }
};

export const organizationsRootSaga = function*() {
  yield all([
    call(snapshotSaga),
    call(initializeLastOrganizationIdSaga),
    call(syncLastOrganizationIdSaga),
  ]);
};
