import { Reducer } from 'redux';

import { AccountAction, AccountState } from '../types/account';

const defaultState: AccountState = {
  updateAccountTask: { pending: false },
};

export const reducer: Reducer<AccountState, AccountAction> = (
  maybeState,
  action,
) => {
  const state = maybeState ?? defaultState;

  switch (action.type) {
    case '@@account/UPDATE_SNAPSHOT':
      return {
        ...state,
        snapshot: action.payload.snapshot,
      };
    case '@@account/UPDATE_DATA_START':
      return {
        ...state,
        updateAccountTask: { pending: true },
      };
    case '@@account/UPDATE_DATA_SUCCESS':
      return {
        ...state,
        updateAccountTask: { pending: false },
      };
    case '@@account/UPDATE_DATA_ERROR':
      return {
        ...state,
        updateAccountTask: { error: action.payload.error, pending: false },
      };
    default:
      return state;
  }
};
