import { omit } from 'ramda';
import { Reducer } from 'redux';

import { AuthAction, AuthState } from '../types/auth';

const defaultState: AuthState = {
  codeVerificationTask: { pending: false },
  phoneConfirmationTask: { pending: false },
};

export const reducer: Reducer<AuthState, AuthAction> = (maybeState, action) => {
  const state = maybeState ?? defaultState;

  switch (action.type) {
    case '@@auth/UPDATE_USER':
      return {
        ...state,
        user: action.payload.user,
      };
    case '@@auth/SIGN_IN':
      return {
        ...state,
        codeVerificationTask: omit(['error'], state.codeVerificationTask),
      };
    case '@@auth/PHONE_NUMBER_CONFIRMATION_START':
      return {
        ...state,
        phoneConfirmationTask: {
          pending: true,
        },
      };
    case '@@auth/PHONE_NUMBER_CONFIRMATION_SUCCESS':
      return {
        ...state,
        phoneConfirmationTask: {
          pending: false,
        },
      };
    case '@@auth/PHONE_NUMBER_CONFIRMATION_ERROR':
      return {
        ...state,
        phoneConfirmationTask: {
          error: action.payload.error,
          pending: false,
        },
      };
    case '@@auth/CODE_VERIFICATION_START':
      return {
        ...state,
        codeVerificationTask: {
          pending: true,
        },
      };
    case '@@auth/CODE_VERIFICATION_SUCCESS':
      return {
        ...state,
        codeVerificationTask: {
          pending: false,
        },
      };
    case '@@auth/CODE_VERIFICATION_ERROR':
      return {
        ...state,
        codeVerificationTask: {
          error: action.payload.error,
          pending: false,
        },
      };
    default:
      return state;
  }
};
