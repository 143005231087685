import { isNil, not, pipe } from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from '../store';

const getState = (state: RootState = {}) => state.auth;

export const getUser = createSelector(getState, state => state?.user);

export const getUid = createSelector(getUser, user => user?.uid);

export const getSignedIn = createSelector(getUser, pipe(isNil, not));

export const getPhoneConfirmationTask = createSelector(
  getState,
  state => state?.phoneConfirmationTask,
);

export const getCodeVerificationTask = createSelector(
  getState,
  state => state?.codeVerificationTask,
);
