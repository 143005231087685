import { ConfirmationResult, RecaptchaVerifier } from '@firebase/auth-types';
import { isNil } from 'ramda';
import { buffers, eventChannel } from 'redux-saga';
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import {
  codeVerificationError,
  codeVerificationSuccess,
  phoneNumberConfirmationError,
  phoneNumberConfirmationStart,
  phoneNumberConfirmationSuccess,
  updateUser,
} from '../actions/auth';
import { auth } from '../fragments/firebase-auth';
import { notifier } from '../fragments/notifier';
import { getUser } from '../selectors/auth';
import { CodeVerificationStartAction, SignInAction } from '../types/auth';

const updateUserSaga = function*({ user }: any) {
  yield put(updateUser(user));
};

export const userSaga = function*() {
  const channel = eventChannel(
    emitter => auth().onAuthStateChanged(user => emitter({ user })),
    buffers.sliding(1),
  );

  yield takeLeading(channel, updateUserSaga);
};

export const verificationCodeSaga = function*(
  confirmationResult: ConfirmationResult,
  { payload: { code } }: CodeVerificationStartAction,
) {
  try {
    yield call([confirmationResult, 'confirm'], code);
    yield put(codeVerificationSuccess());

    return;
  } catch (error) {
    notifier('error', error.message);
    yield put(codeVerificationError(error));
  }
};

/**
 * Returns confirmation result if the phone confirmation was successful or null
 * if it wasn't.
 */
export const phoneNumberConfirmationSaga = function*(
  phoneNumber: string,
  verifier: RecaptchaVerifier,
) {
  try {
    yield call([verifier, 'verify']);

    yield put(phoneNumberConfirmationStart());

    const confirmationResult = yield call(
      [auth(), 'signInWithPhoneNumber'],
      phoneNumber,
      verifier,
    );

    yield put(phoneNumberConfirmationSuccess());

    return confirmationResult;
  } catch (error) {
    notifier('error', error.message);
    yield put(phoneNumberConfirmationError(error));
    return null;
  }
};

export const signInSaga = function*({
  payload: { phoneNumber, verifier },
}: SignInAction) {
  const user = yield select(getUser);

  if (user === null) {
    const phoneConfirmationResult = yield call(
      phoneNumberConfirmationSaga,
      phoneNumber,
      verifier,
    );

    if (phoneConfirmationResult === null) {
      return;
    }

    yield takeLeading(
      '@@auth/CODE_VERIFICATION_START',
      verificationCodeSaga,
      phoneConfirmationResult,
    );
  }
};

export const signOutSaga = function*() {
  const user = yield select(getUser);

  if (!isNil(user)) {
    yield call([auth(), 'signOut']);
  }
};

export const authRootSaga = function*() {
  yield all([
    call(userSaga),
    takeLeading('@@auth/SIGN_OUT', signOutSaga),
    takeLatest('@@auth/SIGN_IN', signInSaga),
  ]);
};
