import { createSelector } from 'reselect';

import { RootState } from '../store';

const getState = (state: RootState) => state?.account;

export const getAccountSnapshot = createSelector(
  getState,
  state => state?.snapshot,
);

export const getUpdateAccountTask = createSelector(
  getState,
  state => state?.updateAccountTask,
);
