import React from 'react';

const CompanyLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="160"
    height="49.61"
    viewBox="0 0 255.56 49.61"
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_6"
          data-name="Path 6"
          d="M29.135-35.831H16.208L11.471-17.168H24.317Z"
          transform="translate(-11.471 35.831)"
          fill="#ffcd41"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Path_5"
          data-name="Path 5"
          d="M-19,1741.947H2668.921V-50H-19Z"
          transform="translate(19 50)"
          fill="#ffcd41"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          id="Path_9"
          data-name="Path 9"
          d="M32.347-13.725H19.821L15.084,4.938H27.609Z"
          transform="translate(-15.084 13.725)"
          fill="#8ec548"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          id="Path_8"
          data-name="Path 8"
          d="M-19,1741.947H2668.921V-50H-19Z"
          transform="translate(19 50)"
          fill="#8ec548"
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          id="Path_24"
          data-name="Path 24"
          d="M52.626-12.526v10.08H50.505V-26.682h8.878a9.519,9.519,0,0,1,6.285,1.927,6.416,6.416,0,0,1,2.2,5.114,6.569,6.569,0,0,1-2.2,5.188,9.375,9.375,0,0,1-6.285,1.927Zm0-1.631h6.756a6.686,6.686,0,0,0,4.792-1.556,4.982,4.982,0,0,0,1.571-3.854,5.271,5.271,0,0,0-1.571-3.854,6.559,6.559,0,0,0-4.792-1.556H52.626Z"
          transform="translate(-50.505 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-6">
        <path
          id="Path_23"
          data-name="Path 23"
          d="M-19,1741.947H2668.921V-50H-19Z"
          transform="translate(19 50)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-7">
        <path
          id="Path_27"
          data-name="Path 27"
          d="M68.62-4.151H81.347v1.7H66.5V-26.682H68.62V-4.151Z"
          transform="translate(-66.499 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-9">
        <path
          id="Path_30"
          data-name="Path 30"
          d="M97.509-26.682v16.454a7.355,7.355,0,0,1-2.593,6.077,10.167,10.167,0,0,1-6.678,2.075,9.806,9.806,0,0,1-6.6-2.075,7.539,7.539,0,0,1-2.514-6.077V-26.682h2.121v16.454a6.021,6.021,0,0,0,1.964,4.817,7.424,7.424,0,0,0,5.028,1.7,7.853,7.853,0,0,0,5.185-1.7,5.921,5.921,0,0,0,1.964-4.817V-26.682Z"
          transform="translate(-79.125 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-11">
        <path
          id="Path_33"
          data-name="Path 33"
          d="M111.888-8.27a4.132,4.132,0,0,0-1.571-3.261,15.784,15.784,0,0,0-5.5-2.223,17.827,17.827,0,0,1-6.364-2.594,5.168,5.168,0,0,1-2.2-4.3,5.3,5.3,0,0,1,2.435-4.521,10.309,10.309,0,0,1,6.206-1.779,9.514,9.514,0,0,1,6.442,2.075,6.152,6.152,0,0,1,2.357,5.114l-.079.074h-1.964a5.123,5.123,0,0,0-1.807-4,7.242,7.242,0,0,0-4.949-1.556,7.57,7.57,0,0,0-4.792,1.334,3.865,3.865,0,0,0-1.728,3.261,3.82,3.82,0,0,0,1.571,3.039,15.108,15.108,0,0,0,5.578,2.149,16.642,16.642,0,0,1,6.285,2.668,5.44,5.44,0,0,1,2.2,4.447,5.318,5.318,0,0,1-2.514,4.6,11.442,11.442,0,0,1-6.442,1.7A11.657,11.657,0,0,1,98.375-3.9a5.675,5.675,0,0,1-2.75-5.336v-.074h1.964a4.547,4.547,0,0,0,2.278,4.15,10.025,10.025,0,0,0,5.264,1.334,8.965,8.965,0,0,0,4.95-1.26A3.477,3.477,0,0,0,111.888-8.27Z"
          transform="translate(-95.621 26.947)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-13">
        <path
          id="Path_36"
          data-name="Path 36"
          d="M126.9-12.3H116.769v9.931h-5.264v-24.31h16.734v4.076h-11.47v6.226H126.9V-12.3Z"
          transform="translate(-111.506 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-15">
        <path
          id="Path_39"
          data-name="Path 39"
          d="M130.911-6.449h11.235v4h-16.5V-26.682h5.264Z"
          transform="translate(-125.647 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-17">
        <path
          id="Path_42"
          data-name="Path 42"
          d="M155.019-12.9H144.884v6.522h11.941v4H139.621v-24.31h17.205v4.076H144.963v5.781H155.1V-12.9Z"
          transform="translate(-139.621 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-19">
        <path
          id="Path_45"
          data-name="Path 45"
          d="M169.554-12.9H159.419v6.522h11.941v4H154.155v-24.31h17.205v4.076H159.5v5.781h10.135V-12.9Z"
          transform="translate(-154.155 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-path-21">
        <path
          id="Path_48"
          data-name="Path 48"
          d="M188.69-22.606h-7.856V-2.447H175.57V-22.606h-7.778v-4.076h20.9v4.076Z"
          transform="translate(-167.792 26.682)"
          fill="#707070"
        />
      </clipPath>
      <clipPath id="clip-logo-plusfleet-gray-text">
        <rect width="255.56" height="49.61" />
      </clipPath>
    </defs>
    <g
      id="logo-plusfleet-gray-text"
      clipPath="url(#clip-logo-plusfleet-gray-text)"
    >
      <g
        id="Group_83"
        data-name="Group 83"
        transform="translate(-0.376 35.897)"
      >
        <g
          id="Group_5"
          data-name="Group 5"
          transform="translate(15.937 -35.831)"
          clipPath="url(#clip-path)"
        >
          <g
            id="Group_4"
            data-name="Group 4"
            transform="translate(-42.658 -19.836)"
            clipPath="url(#clip-path-2)"
          >
            <path
              id="Path_4"
              data-name="Path 4"
              d="M6.471-40.831H38.134V-8.168H6.471Z"
              transform="translate(29.187 53.667)"
              fill="#ffcd41"
            />
          </g>
        </g>
        <g
          id="Group_7"
          data-name="Group 7"
          transform="translate(21.085 -4.883)"
          clipPath="url(#clip-path-3)"
        >
          <g
            id="Group_6"
            data-name="Group 6"
            transform="translate(-47.716 -50.783)"
            clipPath="url(#clip-path-4)"
          >
            <path
              id="Path_7"
              data-name="Path 7"
              d="M10.084-18.725H41.346V13.938H10.084Z"
              transform="translate(30.632 62.509)"
              fill="#8ec548"
            />
          </g>
        </g>
        <g
          id="Group_17"
          data-name="Group 17"
          transform="translate(70.636 -23.023)"
          clipPath="url(#clip-path-5)"
        >
          <g
            id="Group_16"
            data-name="Group 16"
            transform="translate(-97.304 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_22"
              data-name="Path 22"
              d="M45.505-31.682H76.867V6.553H45.505Z"
              transform="translate(44.799 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_19"
          data-name="Group 19"
          transform="translate(93.027 -23.023)"
          clipPath="url(#clip-path-7)"
        >
          <g
            id="Group_18"
            data-name="Group 18"
            transform="translate(-119.695 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_25"
              data-name="Path 25"
              d="M61.5-31.682H90.347V6.553H61.5Z"
              transform="translate(51.196 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_21"
          data-name="Group 21"
          transform="translate(110.703 -23.023)"
          clipPath="url(#clip-path-9)"
        >
          <g
            id="Group_20"
            data-name="Group 20"
            transform="translate(-137.371 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_28"
              data-name="Path 28"
              d="M74.125-31.682h32.383V6.924H74.125Z"
              transform="translate(56.246 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_23"
          data-name="Group 23"
          transform="translate(133.796 -23.394)"
          clipPath="url(#clip-path-11)"
        >
          <g
            id="Group_22"
            data-name="Group 22"
            transform="translate(-160.464 -32.273)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_31"
              data-name="Path 31"
              d="M90.621-31.947h32.388v38.9H90.621Z"
              transform="translate(62.844 57.22)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_25"
          data-name="Group 25"
          transform="translate(156.034 -23.023)"
          clipPath="url(#clip-path-13)"
        >
          <g
            id="Group_24"
            data-name="Group 24"
            transform="translate(-182.702 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_34"
              data-name="Path 34"
              d="M106.506-31.682h30.733V6.627H106.506Z"
              transform="translate(69.197 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_27"
          data-name="Group 27"
          transform="translate(175.832 -23.023)"
          clipPath="url(#clip-path-15)"
        >
          <g
            id="Group_26"
            data-name="Group 26"
            transform="translate(-202.5 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_37"
              data-name="Path 37"
              d="M120.647-31.682h30.5V6.553h-30.5Z"
              transform="translate(74.853 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_29"
          data-name="Group 29"
          transform="translate(195.394 -23.023)"
          clipPath="url(#clip-path-17)"
        >
          <g
            id="Group_28"
            data-name="Group 28"
            transform="translate(-222.062 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_40"
              data-name="Path 40"
              d="M134.621-31.682h31.2V6.627h-31.2Z"
              transform="translate(80.442 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_31"
          data-name="Group 31"
          transform="translate(215.742 -23.023)"
          clipPath="url(#clip-path-19)"
        >
          <g
            id="Group_30"
            data-name="Group 30"
            transform="translate(-242.41 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_43"
              data-name="Path 43"
              d="M149.155-31.682h31.2V6.627h-31.2Z"
              transform="translate(86.255 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <g
          id="Group_33"
          data-name="Group 33"
          transform="translate(234.833 -23.023)"
          clipPath="url(#clip-path-21)"
        >
          <g
            id="Group_32"
            data-name="Group 32"
            transform="translate(-261.501 -32.644)"
            clipPath="url(#clip-path-6)"
          >
            <path
              id="Path_46"
              data-name="Path 46"
              d="M162.792-31.682h34.9V6.553h-34.9Z"
              transform="translate(91.709 57.326)"
              fill="#707070"
            />
          </g>
        </g>
        <path
          id="Path_62"
          data-name="Path 62"
          d="M.172,23.519H25.857L28.805,11.2H3.529Z"
          transform="translate(0 -28.402)"
          fill="#ff5722"
        />
        <path
          id="Path_63"
          data-name="Path 63"
          d="M28.805,11.2H3.12L.172,23.519H25.448Z"
          transform="translate(25.666 -28.402)"
          fill="#03a9f4"
        />
      </g>
    </g>
  </svg>
);

export default CompanyLogo;
