import { Permissions } from '@9e15/com-uniheap-firestore-schema';
import { all as every, has, isEmpty, isNil } from 'ramda';
import { Action } from 'redux';
import { buffers, EventChannel, eventChannel, Task } from 'redux-saga';
import { call, cancel, fork, put, select, take } from 'redux-saga/effects';

import {
  getAllowedToFunction,
  getTagsRestriction,
} from '../../../selectors/permissions';
import { Dictionary } from '../../../types/generics/dictionary';
import { throttle } from '../../../utils/async';
import { takeSelection } from '../state';

const FIRESTORE_PERMISSION_DENIED_ERROR_CODE = 'permission-denied';
const SNAPSHOTS_EMITTERS_THROTTLING_MS = 400;

export interface SnapshotChannelErrorResult {
  error: unknown;
}

export interface SnapshotChannelSnapshotResult<TSnapshot = unknown> {
  snapshot: TSnapshot;
}

export type SnapshotChannelResult<TSnapshot = unknown> =
  | SnapshotChannelSnapshotResult<TSnapshot>
  | SnapshotChannelErrorResult;

export type SnapshotChannel<TSnapshot = unknown> = EventChannel<
  SnapshotChannelResult<TSnapshot>
>;

export type DocumentSnapshot<TData = unknown> = TData | null;

export type CollectionSnapshot<TData = unknown> = Dictionary<TData>;

export type DocumentSnapshotChannel<TData = unknown> = SnapshotChannel<
  DocumentSnapshot<TData>
>;

export type CollectionSnapshotChannel<TData = unknown> = SnapshotChannel<
  CollectionSnapshot<TData>
>;

export interface OrganizationCollectionRestrictions {
  permissions?: Array<keyof Permissions>;
  taggableCollection?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SnapshotActionCreator = (snapshot: any) => Action;

export const isError = (
  result: SnapshotChannelResult,
): result is SnapshotChannelErrorResult => has('error', result);

export const createDocumentSnapshotChannel = (
  reference: firebase.firestore.DocumentReference,
): DocumentSnapshotChannel =>
  eventChannel(emitter => {
    const snapshotEmitter = throttle(
      SNAPSHOTS_EMITTERS_THROTTLING_MS,
      (doc: firebase.firestore.DocumentSnapshot) =>
        emitter({ snapshot: doc.exists ? doc.data() : null }),
    );
    const errorEmitter = (error: Error) => emitter({ error });

    return reference.onSnapshot(snapshotEmitter, errorEmitter);
  }, buffers.sliding(1));

export const createCollectionSnapshotChannel = (
  query: firebase.firestore.CollectionReference | firebase.firestore.Query,
): CollectionSnapshotChannel =>
  eventChannel(emitter => {
    const snapshotEmitter = throttle(
      SNAPSHOTS_EMITTERS_THROTTLING_MS,
      (snapshot: firebase.firestore.QuerySnapshot) =>
        emitter({
          snapshot: snapshot.docs.reduce(
            (accumulator, doc) => ({ ...accumulator, [doc.id]: doc.data() }),
            {},
          ),
        }),
    );

    const errorEmitter = (error: Error) => emitter({ error });

    return query.onSnapshot(snapshotEmitter, errorEmitter);
  }, buffers.sliding(1));

export const putSnapshotTask = function*(
  result: SnapshotChannelResult,
  snapshotActionCreator: SnapshotActionCreator,
) {
  if (isError(result)) {
    if (
      (result.error as any)?.code === FIRESTORE_PERMISSION_DENIED_ERROR_CODE
    ) {
      return;
    }

    throw result.error;
  }

  yield put(snapshotActionCreator(result.snapshot));
};

export const snapshotWorker = function*(
  channel: SnapshotChannel,
  snapshotActionCreator: SnapshotActionCreator,
) {
  try {
    while (true) {
      const result = yield take(channel);

      yield call(putSnapshotTask, result, snapshotActionCreator);
    }
  } finally {
    channel.close();
  }
};

/** Allows for listening on document snapshots */
export const documentSnapshotWorker = function*(
  reference: firebase.firestore.DocumentReference,
  snapshotActionCreator: SnapshotActionCreator,
) {
  yield call(
    snapshotWorker,
    createDocumentSnapshotChannel(reference),
    snapshotActionCreator,
  );
};

/** Allows for listening on collection or query snapshots */
export const collectionSnapshotWorker = function*(
  query: firebase.firestore.CollectionReference | firebase.firestore.Query,
  snapshotActionCreator: SnapshotActionCreator,
) {
  yield call(
    snapshotWorker,
    createCollectionSnapshotChannel(query),
    snapshotActionCreator,
  );
};

export const runRestrictedCollectionSnapshotWorkerTask = function*(
  query: firebase.firestore.CollectionReference | firebase.firestore.Query,
  snapshotActionCreator: SnapshotActionCreator,
  taggableCollection = false,
) {
  const tagsRestriction: string[] = yield select(getTagsRestriction);

  if (taggableCollection && !isEmpty(tagsRestriction)) {
    yield call(
      collectionSnapshotWorker,
      query.where('tags', 'array-contains-any', tagsRestriction),
      snapshotActionCreator,
    );
  } else {
    yield call(collectionSnapshotWorker, query, snapshotActionCreator);
  }
};

/**
 * Should be used on collections that contain taggable items and/or are
 * restricted by permissions
 */
export const restrictedCollectionSnapshotWorker = function*(
  query: firebase.firestore.CollectionReference | firebase.firestore.Query,
  snapshotActionCreator: SnapshotActionCreator,
  {
    permissions = [],
    taggableCollection = false,
  }: OrganizationCollectionRestrictions,
) {
  let allowedTo = yield select(getAllowedToFunction);

  while (true) {
    const workerTask: Task | undefined = every(allowedTo, permissions)
      ? yield fork(
          runRestrictedCollectionSnapshotWorkerTask,
          query,
          snapshotActionCreator,
          taggableCollection,
        )
      : undefined;

    allowedTo = yield takeSelection(getAllowedToFunction);

    if (!isNil(workerTask)) {
      yield cancel(workerTask);
    }
  }
};
