import '../fragments/permissions';
import '../fragments/team-member';
import '../fragments/auth';
import '../fragments/organizations';
import '../fragments/account';

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '../store';
import Pages from './pages';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Pages />
    </BrowserRouter>
  </Provider>
);

export default App;
