export enum Page {
  Login = 'login',
  AccountInformation = 'account-information',
  RoutesHistory = 'routes-history',
  RoutesSummary = 'routes-summary',
  RouteTracking = 'route-tracking',
}

export interface PageItem {
  component: any;
  exact?: boolean;
  id: Page;
  path?: string;
  children?: PageItem[];
}
