import '../styles/layout.scss';

import {
  BarChartOutlined,
  BellOutlined,
  DashboardOutlined,
  HistoryOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { BackTop, Drawer, Layout as AntdLayout, Menu } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { signOutStart } from '../actions/auth';
import { getOrganizationName } from '../selectors/organizations';
import { store } from '../store';
import { Page } from '../types/routing';
import CompanyLogo from './svgs/company-logo';

interface MenuItem {
  group?: Array<{
    icon: React.ReactNode;
    path: string;
    title: string;
    visible?: boolean;
  }>;
  hasGroup?: boolean;
  icon?: React.ReactNode;
  path?: string;
  title: string;
  visible?: boolean;
}

const pages: MenuItem[] = [
  {
    title: 'Trip',
    hasGroup: true,
    group: [
      {
        icon: <BarChartOutlined />,
        path: `/${Page.RoutesSummary}`,
        title: 'Summary',
        visible: true,
      },
      {
        icon: <DashboardOutlined />,
        path: `/${Page.RouteTracking}`,
        title: 'Tracking',
        visible: true,
      },
      {
        icon: <HistoryOutlined />,
        path: `/${Page.RoutesHistory}`,
        title: 'History',
        visible: true,
      },
    ],
  },
  {
    title: 'Account',
    hasGroup: true,
    group: [
      {
        icon: <UserOutlined />,
        path: `/${Page.AccountInformation}`,
        title: 'Information',
        visible: true,
      },
    ],
  },
];

interface Props {
  children?: React.ReactNode;
  location?: any;
}

const Layout: React.FC<Props> = ({ children, location }) => {
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);

  const organizationName = useSelector(getOrganizationName);

  return (
    <div className="app-layout">
      <Drawer
        placement="left"
        closable={false}
        className="navigation-drawer"
        onClose={() => setDrawerVisibility(false)}
        visible={drawerVisibility}
      >
        <div className="unfold-icon-wrapper">
          <MenuFoldOutlined
            onClick={() => setDrawerVisibility(false)}
            className="icon"
          />
        </div>

        <div className="organization-wrapper">
          <BellOutlined />
          Organization:
          <strong>{organizationName}</strong>
        </div>

        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          onClick={() => setDrawerVisibility(false)}
        >
          {pages.map(page =>
            page?.hasGroup === true ? (
              <Menu.ItemGroup key={page.title} title={page.title}>
                {page.group?.map(item => (
                  <Menu.Item key={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                    <NavLink to={item.path} />
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            ) : (
              <Menu.Item key={page.path}>
                {page.icon}
                <span>{page.title}</span>
                <NavLink to={page.path as string} />
              </Menu.Item>
            ),
          )}
        </Menu>
      </Drawer>

      <AntdLayout>
        <AntdLayout.Header>
          <MenuUnfoldOutlined
            className="icon"
            onClick={() => setDrawerVisibility(true)}
          />
          <CompanyLogo />
          <LogoutOutlined
            className="icon"
            onClick={() => store.dispatch(signOutStart())}
          />
        </AntdLayout.Header>

        <AntdLayout.Content className="app-content">
          {children}
        </AntdLayout.Content>
      </AntdLayout>
      <BackTop />
    </div>
  );
};

export default Layout;
