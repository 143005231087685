import { isNil } from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from '../store';

export const getState = (state: RootState = {}) => state.organizations;

export const getOrganizationsSnapshot = createSelector(
  getState,
  state => state?.snapshot,
);

export const getOrganizationsEntries = createSelector(
  getOrganizationsSnapshot,
  snapshot => (snapshot === undefined ? undefined : Object.entries(snapshot)),
);

export const getLastOrganizationId = createSelector(
  getState,
  state => state?.lastOrganizationId,
);

export const getOrganizationId = createSelector(
  getLastOrganizationId,
  getOrganizationsEntries,
  (lastOrganizationId, organizationEntries): string | undefined => {
    if (!isNil(lastOrganizationId)) {
      return lastOrganizationId;
    }

    const firstAvailableOrganizationId = organizationEntries?.[0]?.[0];

    if (!isNil(firstAvailableOrganizationId)) {
      return firstAvailableOrganizationId;
    }

    return undefined;
  },
);
export const getOrganizationName = createSelector(
  getOrganizationId,
  getOrganizationsSnapshot,
  (ogranizationId, snapshot): string | undefined => {
    if (!isNil(snapshot) && !isNil(ogranizationId)) {
      const currentOrganization = snapshot[ogranizationId];

      return isNil(currentOrganization) ? undefined : currentOrganization.name;
    }
  },
);
