import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';

import { getAccountSnapshot } from './account';
import { getUser } from './auth';
import { getOrganizationsSnapshot } from './organizations';
import {
  getAdministratorSnapshot,
  getPermissionsSnapshot,
} from './permissions';

export const getLoading = createSelector(
  getUser,
  getPermissionsSnapshot,
  getAccountSnapshot,
  getOrganizationsSnapshot,
  getAdministratorSnapshot,
  (user, permissions, account, organizations, administrator) => {
    const permissionsValid =
      permissions !== undefined || isEmpty(organizations);

    return (
      user === undefined ||
      (user === null
        ? false
        : permissionsValid === false ||
          [administrator, organizations, account].every(
            snapshot => snapshot !== undefined,
          ) === false)
    );
  },
);
