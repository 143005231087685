import { UsersEntity } from '@9e15/com-uniheap-firestore-schema';

import {
  UpdateAccountErrorAction,
  UpdateAccountStartAction,
  UpdateAccountSuccessAction,
  UpdateSnapshotAction,
} from '../types/account';

export const updateSnapshot = (
  snapshot: UsersEntity | null,
): UpdateSnapshotAction => ({
  payload: { snapshot },
  type: '@@account/UPDATE_SNAPSHOT',
});

export const updateAccountStart = (
  data: Partial<UsersEntity>,
): UpdateAccountStartAction => ({
  payload: { data },
  type: '@@account/UPDATE_DATA_START',
});

export const updateAccountSuccess = (): UpdateAccountSuccessAction => ({
  type: '@@account/UPDATE_DATA_SUCCESS',
});

export const updateAccountError = (
  error: UpdateAccountErrorAction['payload']['error'],
): UpdateAccountErrorAction => ({
  payload: { error },
  type: '@@account/UPDATE_DATA_ERROR',
});
