import { UsersEntity } from '@9e15/com-uniheap-firestore-schema';
import { has, isNil } from 'ramda';
import { all, call, put, select, takeLeading } from 'redux-saga/effects';

import {
  updateAccountError,
  updateAccountSuccess,
  updateSnapshot,
} from '../actions/account';
import { firestore } from '../fragments/firebase-firestore';
import { getUid, getUser } from '../selectors/auth';
import {
  UpdateAccountStartAction,
  UpdateSnapshotAction,
} from '../types/account';
import { documentSnapshotWorker } from './utils/firestore';
import { parametrizedWorker } from './utils/workers/parametrized-worker';

export const getUserRef = (uid: string) =>
  firestore()
    .collection('users')
    .doc(uid);

export const updateDataTask = function*({
  payload: { data },
}: UpdateAccountStartAction) {
  const uid: string | undefined = yield select(getUid);

  if (!isNil(uid) && !isNil(data)) {
    try {
      yield call([getUserRef(uid), 'set'], data, { merge: true });
      yield put(updateAccountSuccess());
    } catch (error) {
      yield put(updateAccountError(error));
    }
  }
};

export const createAccountDocumentIfNotExists = (
  uid: string,
  phoneNumber: string,
) =>
  firestore().runTransaction(async transaction => {
    const userRef = getUserRef(uid);
    const userSnapshot = await transaction.get(userRef);
    const userEntity: UsersEntity | undefined = userSnapshot.data();

    const currentPhoneNumber =
      userEntity === undefined ? undefined : userEntity.phoneNumber;

    const userEntityUpdate: UsersEntity = {
      phoneNumber:
        currentPhoneNumber === undefined ? phoneNumber : currentPhoneNumber,
    };

    transaction.set(userRef, userEntityUpdate, { merge: true });
  });

export const createAccountIfNotExistsTask = function*(
  uid: string,
  phoneNumber: string,
) {
  try {
    yield call(createAccountDocumentIfNotExists, uid, phoneNumber);
  } catch (error) {
    throw new Error(error);
  }
};

export const checkIfAccountNotExistsSaga = function*({
  payload: { snapshot },
}: UpdateSnapshotAction) {
  const user = yield select(getUser);

  if (
    (isNil(snapshot) || !has('phoneNumber', snapshot)) &&
    has('phoneNumber', user) &&
    has('uid', user)
  ) {
    yield call(createAccountIfNotExistsTask, user.uid, user.phoneNumber);
  }
};

export const snapshotSaga = function*() {
  yield parametrizedWorker([getUid], function*([uid]) {
    if (!isNil(uid)) {
      yield call(documentSnapshotWorker, getUserRef(uid), updateSnapshot);
    }
  });
};

export const accountRootSaga = function*() {
  yield all([
    call(snapshotSaga),
    takeLeading('@@account/UPDATE_DATA_START', updateDataTask),
    takeLeading('@@account/UPDATE_SNAPSHOT', checkIfAccountNotExistsSaga),
  ]);
};
