import { OrganizationPermissionsEntity } from '@9e15/com-uniheap-firestore-schema';

import {
  UpdateAdministratorSnapshotAction,
  UpdatePermissionsSnapshotAction,
} from '../types/permissions';

export const updateAdministratorSnapshot = (
  snapshot: {} | null,
): UpdateAdministratorSnapshotAction => ({
  payload: { snapshot },
  type: '@@permissions/UPDATE_ADMINISTRATOR_SNAPSHOT',
});

export const updatePermissionsSnapshot = (
  snapshot: OrganizationPermissionsEntity | null,
): UpdatePermissionsSnapshotAction => ({
  payload: { snapshot },
  type: '@@permissions/UPDATE_PERMISSIONS_SNAPSHOT',
});
