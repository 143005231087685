import { lensIndex, repeat, set } from 'ramda';
import { Task } from 'redux-saga';
import {
  all,
  call,
  CallEffect,
  cancel,
  fork,
  race,
  select,
} from 'redux-saga/effects';
import { Selector } from 'reselect';

import { RootState } from '../../../store';
import { takeSelection } from '../state';

type GenericSelector = Selector<RootState, unknown>;

type WorkerGenerator<
  TParameters extends unknown[],
  TPreviousParameters extends unknown[]
> = (
  currentValues: TParameters,
  previousValues: TPreviousParameters,
) => Generator;

interface ParametrizedWorker {
  <TS0 extends GenericSelector>(
    selectors: [TS0],
    workerGenerator: WorkerGenerator<
      [ReturnType<TS0>],
      [ReturnType<TS0> | undefined]
    >,
  ): CallEffect;
  <TS0 extends GenericSelector, TS1 extends GenericSelector>(
    selectors: [TS0, TS1],
    workerGenerator: WorkerGenerator<
      [ReturnType<TS0>, ReturnType<TS1>],
      [ReturnType<TS0> | undefined, ReturnType<TS1> | undefined]
    >,
  ): CallEffect;
  <
    TS0 extends GenericSelector,
    TS1 extends GenericSelector,
    TS2 extends GenericSelector
  >(
    selectors: [TS0, TS1, TS2],
    workerGenerator: WorkerGenerator<
      [ReturnType<TS0>, ReturnType<TS1>, ReturnType<TS2>],
      [
        ReturnType<TS0> | undefined,
        ReturnType<TS1> | undefined,
        ReturnType<TS2> | undefined,
      ]
    >,
  ): CallEffect;
  <
    TS0 extends GenericSelector,
    TS1 extends GenericSelector,
    TS2 extends GenericSelector,
    TS3 extends GenericSelector
  >(
    selectors: [TS0, TS1, TS2, TS3],
    workerGenerator: WorkerGenerator<
      [ReturnType<TS0>, ReturnType<TS1>, ReturnType<TS2>, ReturnType<TS3>],
      [
        ReturnType<TS0> | undefined,
        ReturnType<TS1> | undefined,
        ReturnType<TS2> | undefined,
        ReturnType<TS3> | undefined,
      ]
    >,
  ): CallEffect;
}

export const parametrizedWorkerGenerator = function*(
  selectors: GenericSelector[],
  workerGenerator: WorkerGenerator<unknown[], unknown[]>,
) {
  const parametersRaceEffect = race(
    selectors.reduce(
      (accumulator, selector, index) => ({
        ...accumulator,
        [index]: takeSelection(selector),
      }),
      {},
    ),
  );

  let previousParameters: Array<
    ReturnType<GenericSelector> | undefined
  > = repeat(undefined, selectors.length);
  let currentParameters: Array<ReturnType<GenericSelector>> = yield all(
    selectors.map(selector => select(selector)),
  );

  while (true) {
    const workerTask: Task = yield fork(
      workerGenerator,
      currentParameters,
      previousParameters,
    );
    const raceResult = yield parametersRaceEffect;

    previousParameters = currentParameters;
    currentParameters = Object.entries(raceResult).reduce(
      (accumulator, [index, param]) =>
        set(lensIndex(parseInt(index, 10)), param, accumulator),
      previousParameters,
    );

    yield cancel(workerTask);
  }
};

/**
 * Listens on selectors values and forks new worker on every change with last
 * selected values as generator function parameters.
 *
 * @param selectors Array of state selectors which values will be available in the worker generator
 * @param workerGenerator Generator function forked every time a selector value changes
 */
export const parametrizedWorker: ParametrizedWorker = (
  selectors,
  workerGenerator,
) => call(parametrizedWorkerGenerator, selectors, workerGenerator);
