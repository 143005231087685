import { call, select, take } from 'redux-saga/effects';

import { RootState } from '../../../store';

export const takeSelectionGenerator = function*<TResult = unknown>(
  selector: (state: RootState) => TResult,
) {
  const initialValue = yield select(selector);

  while (true) {
    yield take('*');

    const newValue = yield select(selector);

    if (newValue !== initialValue) {
      return newValue;
    }
  }
};

/**
 * Waits for a selector's output to change (uses strict comparison) and returns
 * the new value. The effect is blocking.
 */
export const takeSelection = <TResult = unknown>(
  selector: (state: RootState) => TResult,
) => call(takeSelectionGenerator, selector);
