import '../styles/loader.scss';

import { Spin } from 'antd';
import React from 'react';

interface Props {
  fitContainer?: boolean;
}

const Loader: React.FC<Props> = ({ fitContainer }) => (
  <div
    className="loader-main-container"
    style={{ height: fitContainer ? '100%' : '100vh' }}
  >
    <Spin size="large" />
  </div>
);

export default Loader;
