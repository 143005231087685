import 'firebase/firestore';

import firebase from './firebase-app';

let instance: firebase.firestore.Firestore | undefined;

// Ensure Firestore client is reused across the project
export const firestore: typeof firebase['firestore'] = Object.assign(
  function() {
    if (instance === undefined) {
      instance = firebase.firestore();
    }

    return instance;
  },
  { ...firebase.firestore },
);
