import { isNil } from 'ramda';
import { all, call } from 'redux-saga/effects';

import {
  updateAdministratorSnapshot,
  updatePermissionsSnapshot,
} from '../actions/permissions';
import { firestore } from '../fragments/firebase-firestore';
import { getUid } from '../selectors/auth';
import { getOrganizationId } from '../selectors/organizations';
import { documentSnapshotWorker } from './utils/firestore';
import { parametrizedWorker } from './utils/workers';

const database = firestore();

const getAdministratorReference = (uid: string) =>
  database.collection('administrators').doc(uid);

export const administratorSnapshotSaga = function*() {
  yield parametrizedWorker([getUid], function*([uid]) {
    if (!isNil(uid)) {
      yield call(
        documentSnapshotWorker,
        getAdministratorReference(uid),
        updateAdministratorSnapshot,
      );
    }
  });
};

const getPermissionsReference = (uid: string, organizationId: string) =>
  database
    .collection('organizations')
    .doc(organizationId)
    .collection('permissions')
    .doc(uid);

export const permissionsSnapshotSaga = function*() {
  yield parametrizedWorker([getOrganizationId, getUid], function*([
    organizationId,
    uid,
  ]) {
    if (!isNil(organizationId) && !isNil(uid)) {
      yield call(
        documentSnapshotWorker,
        getPermissionsReference(uid, organizationId),
        updatePermissionsSnapshot,
      );
    }
  });
};

export const permissionsRootSaga = function*() {
  yield all([call(administratorSnapshotSaga), call(permissionsSnapshotSaga)]);
};
