import { Reducer } from 'redux';

import { PermissionsAction, PermissionsState } from '../types/permissions';

const defaultState: PermissionsState = { snapshots: {} };

export const reducer: Reducer<PermissionsState, PermissionsAction> = (
  maybeState,
  action,
) => {
  const state = maybeState ?? defaultState;

  switch (action.type) {
    case '@@permissions/UPDATE_ADMINISTRATOR_SNAPSHOT':
      return {
        ...state,
        snapshots: {
          ...state.snapshots,
          administrator: action.payload.snapshot,
        },
      };
    case '@@permissions/UPDATE_PERMISSIONS_SNAPSHOT':
      return {
        ...state,
        snapshots: {
          ...state.snapshots,
          permissions: action.payload.snapshot,
        },
      };
    default:
      return state;
  }
};
