import { createSelector } from 'reselect';

import { RootState } from '../store';

const getState = (state: RootState = {}) => state.teamMember;

export const getLoggedInTeamMemberSnapshot = createSelector(
  getState,
  state => state?.snapshot,
);

export const getLoggedInTeamMemberId = createSelector(
  getLoggedInTeamMemberSnapshot,
  snapshot => snapshot?.userId,
);
