import { isNil } from 'ramda';
import { all, call, put } from 'redux-saga/effects';

import { updateSnapshot } from '../actions/team-member';
import { firestore } from '../fragments/firebase-firestore';
import { getUid } from '../selectors/auth';
import { getOrganizationId } from '../selectors/organizations';
import { getLoggedInTeamMemberId } from '../selectors/team-member';
import { documentSnapshotWorker } from './utils/firestore/snapshots';
import { parametrizedWorker } from './utils/workers/parametrized-worker';

const getDocumentRef = (organizationId: string, uid: string) =>
  firestore()
    .collection('organizations')
    .doc(organizationId)
    .collection('uids')
    .doc(uid);

export const snapshotSaga = function*() {
  yield parametrizedWorker([getOrganizationId, getUid], function*([
    organizationId,
    uid,
  ]) {
    if (!isNil(organizationId) && !isNil(uid)) {
      yield call(
        documentSnapshotWorker,
        getDocumentRef(organizationId, uid),
        updateSnapshot,
      );
    }
  });
};

export const updateOrganizationUserIdAction = (userId?: string) => ({
  type: '@@user/UPDATE_ORGANIZATION_USER_ID',
  userId,
});

const organizationUserId = function*() {
  yield parametrizedWorker([getLoggedInTeamMemberId], function*([
    teamMemberId,
  ]) {
    if (teamMemberId) {
      yield put(updateOrganizationUserIdAction(teamMemberId));
    }
  });
};

export const teamMemberRootSaga = function*() {
  yield all([call(organizationUserId), call(snapshotSaga)]);
};
