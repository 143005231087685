import { defaultTo, has, isNil, not, pipe } from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from '../store';
import { AllowedToFn } from '../types/permissions';

const getState = (state: RootState = {}) => state.permissions;

export const getAdministratorSnapshot = createSelector(
  getState,
  state => state?.snapshots?.administrator,
);

export const getPermissionsSnapshot = createSelector(
  getState,
  state => state?.snapshots?.permissions,
);

export const getPermissionsTags = createSelector(
  getPermissionsSnapshot,
  snapshot => snapshot?.tags ?? [],
);

export const getAdministrator = createSelector(
  getAdministratorSnapshot,
  pipe(isNil, not),
);

export const getTagsRestriction = createSelector(
  getAdministrator,
  getPermissionsTags,
  (administrator, permissionsTags) =>
    administrator === true ? [] : permissionsTags,
);

export const getHasTagsRestriction = createSelector(
  getAdministrator,
  getPermissionsTags,
  (administrator, permissionsTags) =>
    administrator !== true &&
    permissionsTags !== undefined &&
    permissionsTags.length > 0,
);

export const getAllowedToFunction = createSelector(
  getAdministrator,
  getPermissionsSnapshot,
  (administrator, permissions): AllowedToFn =>
    administrator
      ? () => true
      : permissionId => has(permissionId, defaultTo({}, permissions)),
);
