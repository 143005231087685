import { Reducer } from 'redux';

import { OrganizationsAction } from '../types/organizations';
import { OrganizationsState } from '../types/organizations';

const defaultState: OrganizationsState = {};

export const reducer: Reducer<OrganizationsState, OrganizationsAction> = (
  maybeState,
  action,
) => {
  const state = maybeState ?? defaultState;

  switch (action.type) {
    case '@@organizations/UPDATE_SNAPSHOT':
      return {
        ...state,
        snapshot: action.payload.snapshot,
      };
    case '@@organizations/INITIALIZE_LAST_ORGANIZATION_ID':
      return state.lastOrganizationId === undefined
        ? { ...state, lastOrganizationId: action.payload.organizationId }
        : state;
    case '@@organizations/SYNC_LAST_ORGANIZATION_ID':
      return {
        ...state,
        lastOrganizationId: action.payload.organizationId,
      };
    default:
      return state;
  }
};
