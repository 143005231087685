import {
  InitializeLastOrganizationIdAction,
  SyncLastOrganizationIdAction,
  UpdateSnapshotAction,
} from '../types/organizations';

export const updateSnapshot = (
  snapshot: UpdateSnapshotAction['payload']['snapshot'],
): UpdateSnapshotAction => ({
  payload: { snapshot },
  type: '@@organizations/UPDATE_SNAPSHOT',
});

export const initializeLastOrganizationId = (
  organizationId: string | undefined,
): InitializeLastOrganizationIdAction => ({
  payload: { organizationId },
  type: '@@organizations/INITIALIZE_LAST_ORGANIZATION_ID',
});

export const syncLastOrganizationId = (
  organizationId: string,
): SyncLastOrganizationIdAction => ({
  payload: { organizationId },
  type: '@@organizations/SYNC_LAST_ORGANIZATION_ID',
});
