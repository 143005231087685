import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Reducer,
  Store,
  StoreEnhancer,
} from 'redux';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import { default as sagaMiddlewareFactory } from 'redux-saga';

import { AccountState } from '../types/account';
import { AuthState } from '../types/auth';
import { DriverState } from '../types/driver';
import { ObjectsState } from '../types/objects';
import { OrganizationsState } from '../types/organizations';
import { PermissionsState } from '../types/permissions';
import { RoutesHistoryPageState } from '../types/routes-history-page';
import { RoutesSummaryPageState } from '../types/routes-summary-page';
import { TeamMemberState } from '../types/team-member';

const lazyReducerEnhancer: StoreEnhancer = nextCreator => (
  origReducer,
  preloadedState,
) => {
  let lazyReducers = {};
  const nextStore = nextCreator(origReducer, preloadedState);

  return {
    ...nextStore,
    addReducers(newReducers: any) {
      this.replaceReducer(
        combineReducers(
          (lazyReducers = { ...lazyReducers, ...newReducers }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any,
      );
    },
  };
};

/*
 * A top-level state object.
 */
export interface RootState {
  account?: AccountState;
  auth?: AuthState;
  driver?: DriverState;
  objects?: ObjectsState;
  organizations?: OrganizationsState;
  permissions?: PermissionsState;
  routesHistoryPage?: RoutesHistoryPageState;
  routesSummaryPage?: RoutesSummaryPageState;
  teamMember?: TeamMemberState;
}

export type Reducers = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [TKey in keyof RootState]?: Reducer<Required<RootState>[TKey], any>;
};

/*
 * Initializes the Redux store with a lazyReducerEnhancer (so that you can
 * lazily add reducers after the store has been created) and redux-saga (so
 * that you can dispatch async actions).
 */
export const saga = sagaMiddlewareFactory();

interface EnhancedStore extends Store {
  addReducers(newReducers: Reducers): void;
}

const middlewares = [saga, batchDispatchMiddleware];

export const store = createStore(
  state => state ?? {},
  compose(lazyReducerEnhancer, applyMiddleware(...middlewares)),
) as EnhancedStore;
