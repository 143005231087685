import { Reducer } from 'redux';

import { TeamMemberAction, TeamMemberState } from '../types/team-member';

const defaultState: TeamMemberState = {};

export const reducer: Reducer<TeamMemberState, TeamMemberAction> = (
  maybeState,
  action,
) => {
  const state = maybeState ?? defaultState;

  switch (action.type) {
    case '@@team-member/UPDATE_SNAPSHOT':
      return {
        ...state,
        snapshot: action.payload.snapshot,
      };
    default:
      return state;
  }
};
