import { User } from 'firebase';

import {
  CodeVerificationErrorAction,
  CodeVerificationStartAction,
  CodeVerificationSuccessAction,
  PhoneNumberConfirmationErrorAction,
  PhoneNumberConfirmationStartAction,
  PhoneNumberConfirmationSuccessAction,
  SignInAction,
  SignOutAction,
  UpdateUserAction,
} from '../types/auth';

export const updateUser = (user: User): UpdateUserAction => ({
  payload: { user },
  type: '@@auth/UPDATE_USER',
});

export const codeVerificationError = (
  error: CodeVerificationErrorAction['payload']['error'],
): CodeVerificationErrorAction => ({
  payload: { error },
  type: '@@auth/CODE_VERIFICATION_ERROR',
});

export const codeVerificationStart = (
  code: CodeVerificationStartAction['payload']['code'],
): CodeVerificationStartAction => ({
  payload: { code },
  type: '@@auth/CODE_VERIFICATION_START',
});

export const codeVerificationSuccess = (): CodeVerificationSuccessAction => ({
  type: '@@auth/CODE_VERIFICATION_SUCCESS',
});

export const phoneNumberConfirmationError = (
  error: PhoneNumberConfirmationErrorAction['payload']['error'],
): PhoneNumberConfirmationErrorAction => ({
  payload: { error },
  type: '@@auth/PHONE_NUMBER_CONFIRMATION_ERROR',
});

export const signInStart = (
  phoneNumber: SignInAction['payload']['phoneNumber'],
  verifier: SignInAction['payload']['verifier'],
): SignInAction => ({
  payload: { phoneNumber, verifier },
  type: '@@auth/SIGN_IN',
});

export const signOutStart = (): SignOutAction => ({ type: '@@auth/SIGN_OUT' });

export const phoneNumberConfirmationStart = (): PhoneNumberConfirmationStartAction => ({
  type: '@@auth/PHONE_NUMBER_CONFIRMATION_START',
});

export const phoneNumberConfirmationSuccess = (): PhoneNumberConfirmationSuccessAction => ({
  type: '@@auth/PHONE_NUMBER_CONFIRMATION_SUCCESS',
});
